/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import './CartIcon.style';

/** @namespace MasafiFrontend/Component/Icons/CartIcon/Component */
export class CartIconComponent extends PureComponent {
    render() {
        return (
             <svg
               block="CartIcon"
               width="25px"
               height="25px"
               viewBox="0 0 25 30"
               version="1.1"
             >
                 <g id="02_Navigation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                     <g id="M_02_Navigation_Menu-Active_02" transform="translate(-181.000000, -744.000000)">
                         <g id="Menu_Bottom_Hamburger-Menu-Active" transform="translate(0.000000, 724.000000)">
                             <g id="Shopping_icon" transform="translate(181.000000, 21.000000)">
                                 <g id="Shopping-Bag" fill="#FFFFFF" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="0.4">
                                     <g id="Cart" transform="translate(1.750000, 0.000000)">
                                         <path d="M23.3091221,22.6450237 L21.8513392,7.52494945 C21.7420152,6.44000663 20.7944824,5.63506517 19.6643394,5.63506517 L17.1860173,5.63506517 L17.1860173,5.28496345 C17.1860173,3.81495623 16.5664368,2.4848872 15.5822479,1.53982006 C14.5614484,0.595002922 13.2130089,0 11.6822002,0 L11.6458441,0 C8.58429175,0 6.14228734,2.52001238 6.14228734,5.45996431 L6.14228734,5.63501517 L3.70028293,5.63501517 C2.57046537,5.63501517 1.62262661,6.4750818 1.51328313,7.52489945 L0.0188571367,22.6449737 C-0.236401315,25.5148628 2.13283111,28 5.12142275,28 L18.2061927,28 C21.2313623,28 23.6006663,25.5148628 23.3090186,22.6449737 L23.3091221,22.6450237 Z M8.00103475,5.45993931 C8.00103475,3.42992934 9.64112188,1.78485876 11.6822002,1.74998359 C12.6663566,1.74998359 13.5775523,2.10008531 14.3064437,2.80005125 C14.9990175,3.46511702 15.3999436,4.34012131 15.3999436,5.32006363 L15.3999436,5.67016534 L8.00103475,5.66992122 L8.00103475,5.45993931 Z M20.6484307,25.2350364 C20.0285964,25.8998522 19.1540047,26.2499164 18.2062311,26.2499164 L5.12146115,26.2499164 C4.17388274,26.2499164 3.33545254,25.8998147 2.67945674,25.2350364 C2.05987622,24.5699707 1.76826106,23.730154 1.8412218,22.8199621 L3.29900472,7.69988781 C3.29900472,7.52483695 3.48129592,7.38494252 3.66358712,7.38494252 L6.10559154,7.38494252 L6.10559154,9.13495111 C6.10559154,9.62494102 6.50653065,10.0099554 7.01678721,10.0099554 C7.52704377,10.0099554 7.92798289,9.62494102 7.92798289,9.13495111 L7.92798289,7.84000725 L15.290444,7.84000725 L15.290444,9.17007628 C15.290444,9.66006619 15.6913831,10.0450806 16.2016396,10.0450806 C16.7118962,10.0450806 17.1128353,9.66006619 17.1128353,9.17007628 L17.1128353,7.42006769 L19.5911574,7.42006769 C19.7734486,7.42006769 19.9557398,7.55996212 19.9557398,7.73501298 L21.4501658,22.8550872 C21.5594897,23.6949664 21.2678746,24.5699707 20.6483136,25.2349739 L20.6484307,25.2350364 Z" id="Icon/Shopping-Bag" />
                                     </g>
                                 </g>
                             </g>
                         </g>
                     </g>
                 </g>
             </svg>
        );
    }
}

export default CartIconComponent;
