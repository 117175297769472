/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import './EyeIcon.style';

/** @namespace MasafiFrontend/Component/Icons/EyeIcon/Component */
export class EyeIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="EyeIcon"
              width="17px"
              height="16px"
              viewBox="0 0 17 16"
            >
               <g id="eye" transform="translate(0.000000, 3.000000)">
                    <path d="M15.8913114,4.53703876 C15.7499665,4.35072889 12.3390212,0 8,0 C3.66097884,0 0.25003351,4.35072889 0.108688629,4.53703876 C-0.0362295429,4.72470096 -0.0362295429,4.98440453 0.108688629,5.17206674 C0.25003351,5.3583766 3.66097884,9.70910549 8,9.70910549 C12.3390212,9.70910549 15.7499665,5.3583766 15.8913114,5.17206674 C16.0362295,4.98440453 16.0362295,4.72470096 15.8913114,4.53703876 Z M8,8.65947247 C4.82640739,8.65947247 2.06884877,5.82021513 1.22344637,4.85455275 C2.06884877,3.88626628 4.81840674,1.04963303 8,1.04963303 C11.1815933,1.04963303 13.9311512,3.88889036 14.7765536,4.85455275 C13.9311512,5.82283921 11.1815933,8.65947247 8,8.65947247 Z" id="Shape" />
                    <path d="M8,2.043411 C6.42212529,2.043411 5.14300511,3.30200203 5.14300511,4.85455275 C5.14300511,6.40710346 6.42212529,7.66569449 8,7.66569449 C9.57787471,7.66569449 10.8569949,6.40710346 10.8569949,4.85455275 C10.8552158,3.30272769 9.57713721,2.04516151 8,2.043411 Z M8,6.39512195 C7.135289,6.39512195 6.43430223,5.70538562 6.43430223,4.85455275 C6.43430223,4.00371987 7.135289,3.31398354 8,3.31398354 C8.864711,3.31398354 9.56569777,4.00371987 9.56569777,4.85455275 C9.56392186,5.70466078 8.86397433,6.39337455 8,6.39512195 Z" id="Shape" />
               </g>
            </svg>
        );
    }
}

export default EyeIconComponent;
