/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';

import ExpandableContent from 'Component/ExpandableContent';
import Image from 'Component/Image';
import Link from 'Component/Link';
import {
    Html as SourceHtml,
    WidgetFactory
} from 'SourceComponent/Html/Html.component';

export {
    WidgetFactory
};

/** @namespace MasafiFrontend/Component/Html/Component */
export class HtmlComponent extends SourceHtml {
    static propTypes = {
        ...super.propTypes,
        pageTitle: PropTypes.string.isRequired
    };

    state = {
        faqs: []
    };

    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { name: ['figure'] },
            replace: this.replaceFigure
        },
        {
            query: { attribs: [{ class: 'tab-header' }] },
            replace: this.replaceTab
        },
        {
            query: { attribs: [{ 'data-content-type': 'tab-item' }] },
            replace: this.replaceTabContent
        }

    ];

    replaceTab({ children: element }) {
        const { attribs: { href }, children } = element[0];
        const { children: children2 } = children[0];
        const { data } = children2[0];
        const { faqs } = this.state;
        faqs.push({ id: href, question: data });
        this.setState({ faqs });

        return <></>;
    }

    replaceTabContent({ attribs: { id }, children: element }) {
        const { children } = element[0];
        const { children: children2 } = children[0];
        const { data } = children2[0];
        const { faqs } = this.state;

        // eslint-disable-next-line no-unused-vars
        let currentFaq = faqs.find((faq) => faq.id === `#${id}`);
        currentFaq.answer = data;

        return (
                <ExpandableContent
                  key={ currentFaq.id }
                  heading={ currentFaq.question }
                  mix={ {
                      block: 'FAQ',
                      elem: 'Expandable'
                  } }
                  isArrow={ false }
                >
                    <p>{ currentFaq.answer }</p>
                </ExpandableContent>
        );
    }

    /**
     * Magento sends pagebuilder images as figures containing both mobile and desktop images
     * Issue is both images get loaded and we need only one
     * This method will convert the figure into picture with images served based on media query
     * Fallback mechanism will be domToReact method
     */
    replaceFigure({ attribs, children }) {
        const renderData = this.preparePictureRenderData(attribs, children);

        const { link, picture, notRecognized } = renderData;
        // This figure can't be recognized
        if (typeof notRecognized !== 'undefined') {
            return this.renderUnrecognizedFigure({ attribs, link, notRecognized });
        }
        const renderedPicture = this.renderPicture(picture);
        // The figure is recognized, doesn't contain link
        if (typeof link === 'undefined') {
            return renderedPicture;
        }

        return this.renderFigureLink(link.attribs, renderedPicture);
    }

    /**
     * This method will parse the data and prepare it for rendering
     * It will return an object with the following properties:
     *   link: if link is present this will contain link attribs and children
     *   picture: this will contain the recognized mobile and desktop image
     *   notRecognized: if we can't map to picture we will return this property
     */
    preparePictureRenderData(attribs, children) {
        const renderData = {};
        // Check if the picture is wrapped in link
        if (children.length === 1) {
            const { name, type } = children[0];
            if (type === 'tag' && name === 'a') {
                renderData.link = children[0];
                // replace children with the ones that the link has so we can continue processing
                // eslint-disable-next-line
                children = children[0].children;
            } else {
                renderData.notRecognized = { attribs, children };
                return renderData;
            }
        }
        // Try to find mobile and desktop and extract data for picture elem
        if (children.length === 2) {
            const picture = children.reduce((acc, child) => {
                const { name, type } = child;
                if (type !== 'tag' || name !== 'img') {
                    acc.error = true;
                    return acc;
                }

                const { attribs: { class: className } } = child;
                switch (className) {
                case 'pagebuilder-mobile-hidden':
                    acc.desktop = child;
                    break;
                case 'pagebuilder-mobile-only':
                    acc.mobile = child;
                    break;
                default:
                    acc.error = true;
                }

                return acc;
            }, {});

            if (typeof picture.error !== 'undefined') {
                renderData.notRecognized = { attribs, children };

                return renderData;
            }
            renderData.picture = picture;
        } else {
            renderData.notRecognized = { attribs, children };
        }

        return renderData;
    }

    renderPicture(picture) {
        const {
            mobile: {
                attribs: {
                    src: mobileSrc
                }
            },
            desktop: {
                attribs: imageAttribbs
            }
        } = picture;
        const imageAttributes = attributesToProps(imageAttribbs);

        return (
            <picture>
                <source srcSet={ mobileSrc } media="(max-width: 810px)" />
                <Image { ...imageAttributes } isPlain />
            </picture>
        );
    }

    renderUnrecognizedFigure(renderData) {
        const { attribs, link, notRecognized: { children } } = renderData;
        const renderNotRecognized = domToReact(children, this.parserOptions);
        const figureAttributes = attributesToProps(attribs);
        if (typeof link === 'undefined') {
            return (
                <figure { ...figureAttributes }>
                    { renderNotRecognized }
                </figure>
            );
        }

        return (
            <figure { ...figureAttributes }>
                { this.renderFigureLink(link.attribs, renderNotRecognized) }
            </figure>
        );
    }

    renderFigureLink(attribs, content) {
        const { href, ...attrs } = attribs;
        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link { ...attributesToProps({ ...attrs, to: href }) }>
                        { content }
                    </Link>
                );
            }
        }

        return null;
    }
}

export default HtmlComponent;
