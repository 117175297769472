import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.style';

/** @namespace MasafiFrontend/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
}

export default ProductConfigurableAttributesComponent;
