/* eslint-disable @scandipwa/scandipwa-guidelines/always-both-mappings */
import { connect } from 'react-redux';

import {
    OverlayComponent
} from 'Component/Overlay/Overlay.component';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Overlay/Overlay.container';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

/** @namespace MasafiFrontend/Component/Overlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace MasafiFrontend/Component/Overlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OverlayComponent);
