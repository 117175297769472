/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* eslint-disable max-len */

import { PureComponent } from 'react';

/** @namespace MasafiFrontend/Component/Icons/CalendarIcon/Component */
export class CalendarIconComponent extends PureComponent {
    render() {
        return (
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
              version="1.1"
              fill="#23336B"
            >
                <path
                  d="M2.41777844,16 L13.5822216,16 C14.75259,16 15.7037092,15.0488808 15.7037092,13.8785124 L15.7037092,3.30665083 C15.7037092,2.13628239 14.75259,1.18516319 13.5822216,1.18516319 L12.7407517,1.18516319 L12.7407517,0.592581597 C12.7407517,0.266651822 12.4740504,0 12.1481207,0 C11.8221909,0 11.5555391,0.266651822 11.5555391,0.592581597 L11.5555391,1.18516319 L4.44446094,1.18516319 L4.44446094,0.592581597 C4.44446094,0.266651822 4.17775963,0 3.85182986,0 C3.52590009,0 3.25924826,0.266651822 3.25924826,0.592581597 L3.25924826,1.18516319 L2.41777844,1.18516319 C1.24740999,1.18516319 0.296290798,2.13628239 0.296290798,3.30665083 L0.296290798,13.8785124 C0.296290798,15.0488808 1.24740999,16 2.41777844,16 Z M1.48150347,3.30665083 C1.48150347,2.79111078 1.90223839,2.37037587 2.41777844,2.37037587 L3.25924826,2.37037587 L3.25924826,2.96295747 C3.25924826,3.28888724 3.52590009,3.55553906 3.85182986,3.55553906 C4.17775963,3.55553906 4.44441146,3.28888724 4.44441146,2.96295747 L4.44441146,2.37037587 L11.5555391,2.37037587 L11.5555391,2.96295747 C11.5555391,3.28888724 11.8221909,3.55553906 12.1481207,3.55553906 C12.4740504,3.55553906 12.7407023,3.28888724 12.7407023,2.96295747 L12.7407023,2.37037587 L13.5821721,2.37037587 C14.0977121,2.37037587 14.518447,2.79111078 14.518447,3.30665083 L14.518447,5.03704253 L1.48150347,5.03704253 L1.48150347,3.30665083 Z M1.48150347,6.22220573 L14.518546,6.22220573 L14.518546,13.8785124 C14.518546,14.3940524 14.0978111,14.8147873 13.582271,14.8147873 L2.41777844,14.8147873 C1.90223839,14.8147873 1.48150347,14.3940524 1.48150347,13.8785124 L1.48150347,6.22220573 Z"
                  id="Shape"
                />
            </svg>
        );
    }
}

export default CalendarIconComponent;
