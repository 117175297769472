/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* eslint-disable react/destructuring-assignment */

import { SHOW_POPUP } from 'Store/Popup/Popup.action';

import {
    HIDE_ACTIVE_OVERLAY,
    TOGGLE_OVERLAY
} from './Overlay.action';

/** @namespace MasafiFrontend/Store/Overlay/Reducer/getInitialState */
export const getInitialState = () => ({
    activeOverlay: '',
    areOtherOverlaysOpen: false,
    payload: null
});

/** @namespace MasafiFrontend/Store/Overlay/Reducer/OverlayReducer */
export const OverlayReducer = (
    state = getInitialState(),
    action
) => {
    const { overlayKey, payload } = action;
    const {
        activeOverlay: prevActiveOverlay
    } = state;

    switch (action.type) {
    case TOGGLE_OVERLAY:
    case SHOW_POPUP:
        const activeOverlay = prevActiveOverlay === overlayKey ? '' : overlayKey;
        const areOtherOverlaysOpen = prevActiveOverlay !== '';

        return {
            ...state,
            activeOverlay,
            areOtherOverlaysOpen,
            payload
        };

    case HIDE_ACTIVE_OVERLAY:
        return {
            ...state,
            activeOverlay: '',
            areOtherOverlaysOpen: false,
            payload: null
        };

    default:
        return state;
    }
};

export default OverlayReducer;
