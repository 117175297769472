/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import {
    HIDE_ACTIVE_OVERLAY,
    HIDE_ACTIVE_POPUP,
    hideActiveOverlay,
    hideActivePopup,
    TOGGLE_OVERLAY
} from 'SourceStore/Overlay/Overlay.action.js';

export {
    TOGGLE_OVERLAY,
    HIDE_ACTIVE_OVERLAY,
    HIDE_ACTIVE_POPUP,
    hideActiveOverlay,
    hideActivePopup
};

/** @namespace MasafiFrontend/Store/Overlay/Action/toggleOverlayByKey */
export const toggleOverlayByKey = (overlayKey, payload) => ({
    type: TOGGLE_OVERLAY,
    overlayKey,
    payload
});
