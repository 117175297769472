/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import FIELD_TYPE from 'Component/Field/Field.config';
import CalendarIcon from 'Component/Icons/CalendarIcon';
import { DatePickerComponent as SourceDatePickerComponent } from 'SourceComponent/DatePicker/DatePicker.component';

import './DatePicker.override.style';

/** @namespace MasafiFrontend/Component/DatePicker/Component */
export class DatePickerComponent extends SourceDatePickerComponent {
    static propTypes = {
        ...super.PropTypes,
        disableDate: PropTypes.func
    };

    placeholderMap = {
        [FIELD_TYPE.date]: 'DD/MM/YYYY',
        [FIELD_TYPE.dateTime]: __('Select date & time'),
        [FIELD_TYPE.time]: __('Select time')
    };

    renderCustomInput({ value, onClick }) {
        const { selectedDate, uid, setRef } = this.props;

        return (
            <>
                <input
                  id={ uid }
                  name={ uid }
                  type={ FIELD_TYPE.text }
                  value={ value }
                  onClick={ onClick }
                  ref={ (ref) => setRef(ref) }
                  data-date={ selectedDate }
                  placeholder={ this.getPlaceholder() }
                  inputMode="none"
                  readOnly
                />
                <CalendarIcon />
            </>
        );
    }

    render() {
        const {
            selectedDate,
            onSetDate,
            showTimeSelect,
            showTimeSelectOnly,
            minDate,
            //           maxDate,
            dateFormat,
            timeFormat,
            isClearable,
            popperPlacement,
            filterDate,
            type,
            disableDate
        } = this.props;

        const DateInput = forwardRef(this.renderCustomInput.bind(this));
        return (
            <div block="DatePicker">
                <DatePicker
                  selected={ selectedDate }
                  onChange={ onSetDate }
                  showTimeSelect={ showTimeSelect }
                  showTimeSelectOnly={ showTimeSelectOnly }
                  timeFormat={ timeFormat }
                  timeIntervals={ 15 }
                  dateFormat={ dateFormat }
                  wrapperClassName="DatePicker"
                  popperClassName="Popper"
                  minDate={ minDate }
                  isClearable={ isClearable }
                  customInput={ <DateInput /> }
                  popperPlacement={ popperPlacement }
                  filterDate={ filterDate }
                  type={ type }
                  // eslint-disable-next-line react/jsx-no-bind
                  dayClassName={ (date) => disableDate(date) }
                />
            </div>
        );
    }
}

export default DatePickerComponent;
