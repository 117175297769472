import { EVENT_GTM_NOT_FOUND } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const render = (args, callback, instance) => {
    const { event } = instance.props;

    event(EVENT_GTM_NOT_FOUND);
    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
}

export default {
    'Route/NoMatch/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/NoMatch/Container': {
        'member-function': {
            containerProps
        }
    },
    'Route/NoMatch/Component': {
        'member-function': {
            render
        }
    }
};
