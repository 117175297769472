/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */

import {
    cloneElement, lazy
} from 'react';
import { Route } from 'react-router-dom';

// import CorporateAccount from 'Route/CorporateAccount/CorporateAccount.component';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import {
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS
} from 'SourceComponent/Router/Router.config';
import {
    UrlRewriteType
} from 'Type/Router.type';

import {
    CORPORATE_ACCOUNT,
    CUSTOMER_WINNER,
    MY_BITCASH,
    MY_SAVED_CARD,
    MY_SUBSCRIPTION,
    SWITCH_ITEMS_TYPE
} from './Router.config';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    withStoreRegex
};
export const CorporateAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/CorporateAccount'));
export const CustomerWinner = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/CustomerWinner'));
/** @namespace MasafiFrontend/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    static propTypes = {
        ...super.propTypes,
        urlRewrite: UrlRewriteType.isRequired
    };

    [SWITCH_ITEMS_TYPE] = [
        ...this.SWITCH_ITEMS_TYPE,
        {
            component: <Route path={ withStoreRegex('/my-bitcash') } render={ (props) => <MyAccount { ...props } selectedTab="my-bitcash" /> } />,
            position: 85,
            name: MY_BITCASH
        },
        {
            component: <Route path={ withStoreRegex('/my-subscription') } render={ (props) => <MyAccount { ...props } selectedTab="my-subscription" /> } />,
            position: 85,
            name: MY_SUBSCRIPTION
        },
        {
            component: <Route path={ withStoreRegex('/my-saved-card') } render={ (props) => <MyAccount { ...props } selectedTab="my-saved-card" /> } />,
            position: 85,
            name: MY_SAVED_CARD
        },
        {
            component: <Route path={ withStoreRegex('/corporate-account') } render={ (props) => <CorporateAccount { ...props } /> } />,
            position: 99,
            name: CORPORATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer-announcement') } render={ (props) => <CustomerWinner { ...props } /> } />,
            position: 100,
            name: CUSTOMER_WINNER
        }
    ];

    renderComponentsOfType(type) {
        const { urlRewrite: { type: urlRewriteType } } = this.props;
        const breadcrumbsIndex = this.BEFORE_ITEMS_TYPE.findIndex((item) => item.name === BREADCRUMBS);

        return this.getSortedItems(type)
            .map(({ position, component }, index) => {
                // [MVP-29] DON'T DISPLAY BREADCRUMBS ON TOP OF THE CONTENT
                if (type === BEFORE_ITEMS_TYPE
                    && urlRewriteType === 'CATEGORY'
                    && index === breadcrumbsIndex) {
                    return null;
                }

                return cloneElement(component, { key: position });
            });
    }
}

export default RouterComponent;
