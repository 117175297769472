/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';

import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { DiscountType, PriceItemType } from 'Type/Price.type';

/** @namespace MasafiFrontend/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    static propTypes = {
        ...super.propTypes,
        price: PropTypes.shape({
            finalPrice: PriceItemType,
            finalPriceExclTax: PriceItemType,
            originalPrice: PriceItemType,
            originalPriceExclTax: PriceItemType,
            discount: DiscountType
        })
    };
}

export default ProductPriceComponent;
