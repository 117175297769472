import { Field } from 'Util/Query';

/** @namespace GtmNew/Query/GtmPurchase/Query/GtmPurchaseQuery */
export class GtmPurchaseQuery {
    getGTMPurchase(orderID = '') {
        return new Field('getPurchase')
            .setAlias('purchase')
            .addArgument('orderID', 'String', orderID)
            .addFieldList(this.getGTMPurchaseFields())
            .addField(this.getProductField())
            .addField(this.getShippingFields())
            .addField('additional_data');
    }

    getProductField() {
        return new Field('purchaseProducts')
            .setAlias('products')
            .addFieldList(this.getGTMPurchaseProductFields());
    }

    getGTMPurchaseProductFields() {
        return [
            'name',
            'id',
            'price',
            'quantity',
            'category',
            'dimensions'
        ];
    }

    getGTMPurchaseFields() {
        return [
            'orderPaymentMethod',
            'orderShippingMethod',
            'revenue',
            'tax',
            'shipping',
            'coupon',
            'discount_amount'
        ];
    }

    getShippingFields() {
        return new Field('shippingAddress')
            .addFieldList([
                'street',
                'city',
                'region_id',
                'region',
                'postcode',
                'email'
            ]);
    }
}
export default new GtmPurchaseQuery();
