/* eslint-disable max-len */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace MasafiFrontend/Component/Icons/SuccessIcon/Component */
export class SuccessIconComponent extends PureComponent {
    render() {
        return (
            <svg
              width="48px"
              height="48px"
              viewBox="0 0 48 48"
              version="1.1"
            >
                <g
                  id="06_Checkout-Guest"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                    <g
                      id="D_06_Checkout_Guest_11"
                      transform="translate(-155.000000, -246.000000)"
                    >
                        <g
                          id="Group"
                          transform="translate(155.000000, 246.000000)"
                        >
                            <circle
                              id="Oval"
                              fill="#1CAC48"
                              cx="24"
                              cy="24"
                              r="24"
                            />
                            <path
                              d="M18.3324941,35.2574762 L11.8088202,28.574686 C10.8543118,27.6201776 10.8543118,26.0289198 11.8617254,25.1273554 C12.8162338,24.172847 14.3545476,24.172847 15.309056,25.1802606 L19.8703901,29.847767 L32.4945643,12.9814385 C33.2899943,11.9207578 34.8283648,11.6554692 35.9418949,12.5041748 C37.0025755,13.2996048 37.21492,14.8379753 36.4191585,15.9515054 L22.0456521,35.0454622 C21.6212946,35.6289076 20.9849715,35.9470739 20.2424287,36 L20.0833408,36 C19.4464219,36 18.8097483,35.7347304 18.3324941,35.2574762 Z"
                              id="Path"
                              fill="#FFFFFF"
                              fillRule="nonzero"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default SuccessIconComponent;
