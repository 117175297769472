/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/** @namespace MasafiFrontend/Util/Navigator/isAppleDevice */
export const isAppleDevice = () => ([
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
].includes(navigator.platform) || (navigator.userAgent.includes('Mac')));

/** @namespace MasafiFrontend/Util/Navigator/isAppleWebView */
export const isAppleWebView = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // eslint-disable-next-line eqeqeq
    const standalone = window.navigator.standalone == true;
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    return ios && !standalone && !safari;
};

/** @namespace MasafiFrontend/Util/Navigator/isAppleStandalone */
export const isAppleStandalone = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // eslint-disable-next-line eqeqeq
    const standalone = window.navigator.standalone == true;
    const ios = /iphone|ipod|ipad/.test(userAgent);

    return ios && standalone;
};
