export const UPDATE_CORPORATE_ACCOUNT_FORM = 'UPDATE_CORPORATE_ACCOUNT_FORM';

/**
 * Send message
 * @param {Object} data
 * @namespace MasafiFrontend/Store/CorporateAccountForm/Action/updateCorporateForm */
export const updateCorporateForm = (data = {}) => ({
    type: UPDATE_CORPORATE_ACCOUNT_FORM,
    data
});
