import {
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_INFORMATION_EDIT_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_ORDER_PRINT_URL,
    ACCOUNT_ORDER_URL,
    ACCOUNT_REGISTRATION_URL
} from 'SourceRoute/MyAccount/MyAccount.config';

export {
    ACCOUNT_INFORMATION_EDIT_URL,
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_REGISTRATION_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_ORDER_PRINT_URL,
    ACCOUNT_ORDER_URL
};

// TO BE CHANGED TO /customer/profile
export const ACCOUNT_URL = '/customer/account';
