import AddIcon from 'Component/AddIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldNumber as SourceFieldNumber
} from 'SourceComponent/FieldNumber/FieldNumber.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';
/** @namespace MasafiFrontend/Component/FieldNumber/Component */
export class FieldNumberComponent extends SourceFieldNumber {
    // handleFocus(event) {
    //     return event.target.select();
    // }

    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            events,
            setRef,
            value,
            handleValueChange,
            isDisabled
        } = this.props;

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleValueChange(+e.target.value) }
                  // eslint-disable-next-line react/jsx-no-bind
                  onFocus={ (e) => e.target.select() }
                  type={ FIELD_TYPE.number }
                  aria-label={ __('Value') }
                  value={ value }
                  disabled={ isDisabled }
                />
                <button
                  disabled={ +value === max || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(+value + 1) }
                  aria-label={ __('Add') }
                  type={ FIELD_TYPE.button }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  disabled={ +value === min || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(+value - 1) }
                  aria-label={ __('Subtract') }
                  type={ FIELD_TYPE.button }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberComponent;
