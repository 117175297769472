/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import { UPDATE_CONFIG, UPDATE_CONFIG_DEVICE } from 'SourceStore/Config/Config.action';
import {
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    getCheckoutAgreementData,
    getCountryData,
    getCurrencyData,
    getIndexedRatings,
    MAX_HEIGHT,
    MAX_WIDTH
} from 'SourceStore/Config/Config.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import { isAppleStandalone, isAppleWebView } from 'Util/Navigator/Navigator';

export {
    MAX_WIDTH,
    MAX_HEIGHT,
    DEFAULT_CATGORY_URL_SUFFIX,
    filterStoreConfig,
    getIndexedRatings,
    getCurrencyData,
    getCountryData,
    getCheckoutAgreementData
};

export const {
    countries, reviewRatings, storeConfig, currencyData, cartDisplayConfig
} = BrowserDatabase.getItem('config') || {
    countries: [],
    reviewRatings: [],
    storeConfig: {},
    currencyData: {},
    cartDisplayConfig: {
        display_tax_in_price: '',
        display_tax_in_subtotal: '',
        display_tax_in_shipping_amount: '',
        include_tax_in_order_total: false,
        display_full_tax_summary: false,
        display_zero_tax_subtotal: false,
        minimum_order: {
            active: false
        }
    }
};
/** @namespace MasafiFrontend/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...filterStoreConfig(storeConfig),
    countries,
    reviewRatings,
    checkoutAgreements: [],
    currencyData,
    isLoading: true,
    cartDisplayConfig,
    priceTaxDisplay: {},
    category_url_suffix: DEFAULT_CATGORY_URL_SUFFIX,
    device: {
        isMobile: true,
        android: true,
        ios: false,
        blackberry: false,
        opera: false,
        windows: false,
        standaloneMode: (window.matchMedia('(display-mode: standalone)').matches
        || isAppleStandalone()
        || isAppleWebView())
    }
});

/** @namespace MasafiFrontend/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {}
        } = {},
        device
    } = action;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);
        const { secure_base_media_url } = filteredStoreConfig;
        window.secure_base_media_url = secure_base_media_url;

        return {
            ...state,
            countries: getCountryData(countries, state),
            reviewRatings: getIndexedRatings(reviewRatings),
            checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
            currencyData: getCurrencyData(currencyData, state),
            ...filteredStoreConfig,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false,
            cartDisplayConfig
        };

    case UPDATE_CONFIG_DEVICE:
        return {
            ...state,
            device: {
                ...state.device,
                ...device
            }
        };

    default:
        return state;
    }
};

export default ConfigReducer;
