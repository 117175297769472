/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace MasafiFrontend/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {}

export default FieldSelectComponent;
