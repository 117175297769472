/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import {
    FieldSelectContainer as SourceFieldSelectContainer
} from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace MasafiFrontend/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    isSelectDisabled() {
        const { options, isDisabled } = this.props;

        return options.length === 0 || isDisabled;
    }
}

export default FieldSelectContainer;
