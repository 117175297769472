/* eslint-disable react/no-did-update-set-state */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    DatePickerContainer as SourceDatePickerContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/DatePicker/DatePicker.container';

import DatePicker from './DatePicker.component';

/** @namespace MasafiFrontend/Component/DatePicker/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace MasafiFrontend/Component/DatePicker/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace MasafiFrontend/Component/DatePicker/Container */
export class DatePickerContainer extends SourceDatePickerContainer {
    static propTypes = {
        ...super.PropTypes,
        disableMonday: PropTypes.bool,
        selectedLandmark: PropTypes.number
    };

    static defaultProps = {
        ...super.defaultProps,
        disableMonday: false,
        selectedLandmark: ''
    };

    __construct() {
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        const { selectedDate } = this.props;
        if (prevProps.selectedDate !== selectedDate) {
            this.setState({ selectedDate });
        }
    }

    containerFunctions = {
        // ...super.containerFunctions,
        onSetDate: this.onSetDate.bind(this),
        disableDate: this.disableDate.bind(this)
    };

    onSetDate(date) {
        const { updateSelectedValues } = this.props;

        this.setState({ selectedDate: date }, () => updateSelectedValues(date));
    }

    getNextMonday(date = new Date()) {
        const dateCopy = new Date(date.getTime());
        const WEEK_DAYS = 7;

        const nextMonday = new Date(
            dateCopy.setDate(
                dateCopy.getDate() + ((WEEK_DAYS - dateCopy.getDay() + 1) % WEEK_DAYS || WEEK_DAYS),
            ),
        );

        return nextMonday.toLocaleDateString('en-US');
    }

    getTomorrow(date = new Date()) {
        const dateCopy = new Date(date.getTime());
        const NEXTDAY = 1;

        const nextDayStandard = new Date(
            dateCopy.setDate(
                dateCopy.getDate() + NEXTDAY,
            ),
        );

        return nextDayStandard.toLocaleDateString('en-US');
    }

    isSaturdayAfterSevenPM(date = new Date()) {
        const currentHour = date.getHours();
        const SATURDAY_INDEX = 6;
        const REQUIRED_HOUR = 18;
        const isSaturday = date.getDay() === SATURDAY_INDEX;

        return isSaturday && currentHour >= REQUIRED_HOUR;
    }

    disableEidSaturday(date = new Date()) {
        const eidDay = new Date(date.getTime());
        const EIDSATURDAY = 1;
        const WEEK_DAYS = 7;
        const nextEidDay = new Date(
            eidDay.setDate(
                eidDay.getDate() + ((WEEK_DAYS - eidDay.getDay() - EIDSATURDAY) % WEEK_DAYS || WEEK_DAYS),
            ),
        );

        return nextEidDay.toLocaleDateString('en-US');
    }

    disableDate(calendarDate) {
        const {
            disableMonday
            // selectedLandmark
        } = this.props;

        const SUNDAY_INDEX = 0;
        // const LASTFIVECHARACTER = -5;

        const currentDate = new Date();

        const isSunday = currentDate.getDay() === SUNDAY_INDEX;
        const isDesiredMonday = calendarDate.toLocaleDateString('en-US') === this.getNextMonday();

        const value = disableMonday && isDesiredMonday && (isSunday || this.isSaturdayAfterSevenPM())
            ? 'disabled-date' : '';

        // if (calendarDate.toLocaleDateString('en-US') === this.getTomorrow()
        // && selectedLandmark !== null && selectedLandmark.name.slice(LASTFIVECHARACTER) === '(DUB)') {
        //     return 'disabled-date';
        // }

        return value;
    }

    containerProps() {
        const {
            placeholderText,
            popperPlacement,
            minDate,
            filterDate,
            type,
            isRequired,
            setRef
        } = this.props;

        return {
            ...super.containerProps(),
            placeholderText,
            popperPlacement,
            minDate,
            filterDate,
            type,
            isRequired,
            setRef
        };
    }

    render() {
        return (
            <DatePicker
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DatePickerContainer);
