/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';

/** @namespace MasafiFrontend/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace MasafiFrontend/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace MasafiFrontend/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    state = { ...this.state, quantity: 1 };

    /**
     * Handle item quantity change.
     * @return {void}
     * @param quantity
     */
    handleChangeQuantity(quantity) {
        this.setState({ quantity });
    }

    containerFunctions = {
        ...this.containerFunctions,
        handleChangeQuantity: this.handleChangeQuantity.bind(this)
    };

    showSelectOptionsNotification() {
        const { showNotification, product } = this.props;
        const { options, url } = product;
        if (options.length >= 1) {
            window.location.pathname = url;
        }

        showNotification('info', __('Please, select product options!'));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
