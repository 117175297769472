import { UPDATE_CORPORATE_ACCOUNT_FORM } from './CorporateAccountForm.action';

export const initialState = {
    isLoading: false
};

/** @namespace MasafiFrontend/Store/CorporateAccountForm/Reducer/CorporateAccountFormReducer */
export const CorporateAccountFormReducer = (state = initialState, action) => {
    const {
        type,
        data
    } = action;

    switch (type) {
    case UPDATE_CORPORATE_ACCOUNT_FORM:
        return { ...state, ...data };

    default:
        return state;
    }
};

export default CorporateAccountFormReducer;
