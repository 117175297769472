/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
/* eslint-disable react/require-default-props */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import {
    fetchQuery
} from 'Util/Request';

import CheckoutSuccess from './CheckoutSuccess.component';

/** @namespace MasafiFrontend/Component/CheckoutSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace MasafiFrontend/Component/CheckoutSuccess/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customerEmail: state.MyAccountReducer.customer.email || '',
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace MasafiFrontend/Component/CheckoutSuccess/Container */
export class CheckoutSuccessContainer extends PureComponent {
    static propTypes = {
        email: PropTypes.string,
        customerEmail: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        event: PropTypes.string,
        isEmailAvailable: PropTypes.bool,
        orderID: PropTypes.number,
        isMobile: PropTypes.bool

    };

     state = {
         firstOrder: null,
         redirectUrlSpinWin: null
     };

     async requestSpingandWheelData() {
         const { orderID } = this.props;
         const { customerPlacedOrder } = await fetchQuery(
             CheckoutQuery.getSpinandWin(orderID)
         );

         const {
             is_first_order,
             customer_id
         } = customerPlacedOrder;

         this.setState({ firstOrder: is_first_order });
         this.setState({ redirectUrlSpinWin: customer_id });
     }

     containerProps() {
         const {
             email: guestEmail,
             customerEmail,
             firstName,
             lastName,
             isEmailAvailable,
             orderID,
             event,
             isMobile
         } = this.props;
         const { firstOrder, redirectUrlSpinWin } = this.state;
         return {
             email: customerEmail || guestEmail,
             firstName,
             lastName,
             isEmailAvailable,
             orderID,
             event,
             isMobile,
             firstOrder,
             redirectUrlSpinWin
         };
     }

     render() {
         this.requestSpingandWheelData();
         return <CheckoutSuccess { ...this.containerProps() } />;
     }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutSuccessContainer);
