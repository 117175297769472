import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
/** @namespace MasafiFrontend/Component/ProductPrice/Container */
import { DiscountType, PriceItemType } from 'Type/Price.type';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace MasafiFrontend/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static propTypes = {
        ...super.propTypes,
        price: PropTypes.shape({
            finalPrice: PriceItemType,
            finalPriceExclTax: PriceItemType,
            originalPrice: PriceItemType,
            originalPriceExclTax: PriceItemType,
            discount: DiscountType
        })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
