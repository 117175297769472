import {
    ConfigQuery as SourceConfigQuery
} from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';

/** @namespace MasafiFrontend/Query/Config/Query */
export class ConfigQuery extends SourceConfigQuery {
    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            this._getTawkToField(),
            this._getSocialLoginField()
        ];
    }

    _getTawkToField() {
        return new Field('tawkto')
            .addFieldList([
                'enabled',
                'property_id',
                'widget_id'
            ]);
    }

    _getSocialLoginField() {
        return new Field('social_login')
            .addFieldList([
                'enabled',
                'facebook_app_id',
                'google_app_id'
            ]);
    }
}

export default new ConfigQuery();
