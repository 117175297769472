/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
import { SearchOverlay as SourceSearchOverlay } from 'SourceComponent/SearchOverlay/SearchOverlay.component';

import './SearchOverlay.override.style';

/** @namespace MasafiFrontend/Component/SearchOverlay/Component */
export class SearchOverlayComponent extends SourceSearchOverlay {
    renderNoResults() {
        return (
      <p block="SearchOverlay" elem="noResults">
        { __(
            'We are sorry! There were no products found matching your request.'
        ) }
      </p>
        );
    }
}

export default SearchOverlayComponent;
