import CheckoutSuccess from 'Component/CheckoutSuccess';

import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { cloneElement } from "react";

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const renderDetailsStep = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    const originalElement = callback(...args);

    return cloneElement(
        originalElement.props.children,
        { event }
    );
};

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
}

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            containerProps
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep
        }
    }
};
