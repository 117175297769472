/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import CartIcon from 'Component/Icons/CartIcon';
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace MasafiFrontend/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
    render() {
        const {
            mix,
            addProductToCart,
            layout,
            isDisabled,
            isAdding
        } = this.props;

        return (
            <button
              onClick={ addProductToCart }
              block="Button"
              mix={ mix }
              mods={ { layout, isHollow: true } }
              disabled={ isDisabled || isAdding }
            >
                { this.renderCartIcon() }
                { isAdding ? __('Adding...') : __('Add to cart') }
                <CartIcon />
            </button>
        );
    }
}

export default AddToCartComponent;
