import { connect } from 'react-redux';

import {
    DEFAULT_STATE,
    mapDispatchToProps,
    mapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstractContainer
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';
import { isScrollDisabled, toggleScroll } from 'Util/Browser';

export {
    DEFAULT_STATE,
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace MasafiFrontend/Component/NavigationAbstract/Container */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    handleDesktopRouteChange() {
        const {
            setNavigationState,
            activeOverlay
        } = this.props;

        if (!activeOverlay) {
            return {};
        }

        setNavigationState(this.routeMap['/']);

        return {};
    }

    handlePageScroll() {
        const {
            activeOverlay
        } = this.props;

        if (isScrollDisabled() && (activeOverlay && activeOverlay !== 'category-filter')) {
            toggleScroll(true);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);
