/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Player } from '@lottiefiles/react-lottie-player';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import reactLogo from 'Component/Loader/Masafi_Dot_Blue.json';

import './Loader.style';

/**
 * Loader component
 * Loaders overlay to identify loading
 * @class Loader
 * @namespace MasafiFrontend/Component/Loader/Component */
export class LoaderComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isLoading: true
    };

    renderMain() {
        return (
            <div className="masafi-loader">
                <Player
                  src={ reactLogo }
                  className="player"
                  loop
                  autoplay
                  style={ { height: '80px' } }
                />
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader">
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
