import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace MasafiFrontend/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static defaultProps = {
        heading: '',
        isContentExpanded: false,
        onClick: null,
        children: [],
        isArrow: true,
        mods: {}
    };

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const {
            isArrow
        } = this.props;

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }
}

export default ExpandableContentComponent;
