/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { createPortal } from 'react-dom';

import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';
import { ChildrenType, MixType } from 'Type/Common.type';
import { toggleScroll } from 'Util/Browser';
import { noopFn } from 'Util/Common';

import './Overlay.override.style';

/** @namespace MasafiFrontend/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    static propTypes = {
        mix: MixType,
        id: PropTypes.string.isRequired,
        onVisible: PropTypes.func,
        onHide: PropTypes.func,
        activeOverlay: PropTypes.string.isRequired,
        areOtherOverlaysOpen: PropTypes.bool.isRequired,
        isStatic: PropTypes.bool,
        isRenderInPortal: PropTypes.bool,
        children: ChildrenType,
        isMobile: PropTypes.bool.isRequired,
        isWithBackdrop: PropTypes.bool,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        mix: {},
        children: [],
        onVisible: noopFn,
        isStatic: false,
        onHide: noopFn,
        isRenderInPortal: true,
        isWithBackdrop: false
    };

    overlayRef = createRef();

    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidUpdate(prevProps) {
        const prevWasVisible = this.getIsVisible(prevProps);
        const isVisible = this.getIsVisible();

        if (isVisible && !prevWasVisible) {
            this.onVisible();
        }

        if (!isVisible && prevWasVisible) {
            this.onHide();
        }
    }

    componentWillUnmount() {
        this.unfreezeScroll();
    }

    handleClickOutside(e) {
        const { hideActiveOverlay } = this.props;

        if (this.overlayRef.current && !this.overlayRef.current.contains(e.target)) {
            e.preventDefault();
            hideActiveOverlay();
        }
    }

    onVisible() {
        const { onVisible, isStatic } = this.props;

        if (isStatic) {
            return;
        }

        this.freezeScroll();
        this.overlayRef.current.focus();
        document.addEventListener('click', this.handleClickOutside, true);

        onVisible();
    }

    onHide() {
        const { onHide, isStatic } = this.props;

        if (isStatic) {
            return;
        }

        this.unfreezeScroll();

        document.removeEventListener('click', this.handleClickOutside, true);
        onHide();
    }

    getIsVisible(props = this.props) {
        const { id, activeOverlay, isStatic } = props;

        return isStatic || id === activeOverlay;
    }

    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
    }

    unfreezeScroll() {
        toggleScroll(true);
        document.body.style.marginTop = 0;
        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }

    renderInMobilePortal(content) {
        const { isStatic, isRenderInPortal, isMobile } = this.props;

        if (!isStatic && isMobile && isRenderInPortal) {
            return createPortal(content, document.body);
        }

        return content;
    }

    render() {
        const {
            children,
            mix,
            areOtherOverlaysOpen,
            isStatic,
            isWithBackdrop
        } = this.props;

        const isVisible = this.getIsVisible();

        if (!isWithBackdrop) {
            return this.renderInMobilePortal(
                    <div
                      block="Overlay"
                      ref={ this.overlayRef }
                      mods={ { isVisible, isStatic, isInstant: areOtherOverlaysOpen } }
                      mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
                    >
                        { children && children }
                    </div>
            );
        }

        return (
        <div
          block="Overlay-Backdrop"
          mods={ { isVisible } }
        >
            <div
              block="Overlay"
              ref={ this.overlayRef }
              mods={ { isVisible, isStatic, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { children && children }
            </div>
        </div>
        );
    }
}

export default OverlayComponent;
