/* eslint-disable consistent-return */
export const SUCCESS = 'SUCCESS';
export const ABORTED = 'ABORTED';
export const FAILURE = 'FAILURE';
export const DECLINED = 'DECLINED';
export const ERROR = 'ERROR';

/*
 * Set of helpers related to Payment Status returned by CCavenue
 * @namespace MasafiFrontend/Util/PaymentStatus/getStatusMessage
 */
export const getStatusMessage = (status) => {
    switch (status) {
    case SUCCESS:
        return __('Your payment has been successful.');
    case ABORTED:
        return __('Your order and payment has been cancelled.');
    case FAILURE:
        return __('Payment transaction failed.');
    case DECLINED:
        return __('Payment has been declined. Please check you details/balance.');
    case ERROR:
    default:
        return __('Payment transaction has failed. Please try again or choose a different payment option.');
    }
};
