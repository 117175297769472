import GoogleTagManager from '../component/GoogleTagManager';

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

const BEFORE_ITEMS_TYPE = (originalMember) => {
    const membersPositions = originalMember.map((route) => route.name === 'GoogleTagManager' ? -1 : route.position).filter((num) => num <= MAX_POSITION);
    const maxPosition = Math.max(
        ...membersPositions
    );
    if (Math.min(...membersPositions) < 0) {
        return [...originalMember];
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...originalMember,
        {
            component: <GoogleTagManager />,
            name: 'GoogleTagManager',
            position: maxPosition + INCREASED_POSITION
        }
    ];
};

export const config = {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};

export default config;
