/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import { getBooleanLabel } from 'Util/Product';

import './ProductAttributeValue.override.style';

/** @namespace MasafiFrontend/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    getOptionLabel(value) {
        const {
            attribute: {
                attribute_options,
                is_boolean
            },
            isProductCountVisible
        } = this.props;

        if (attribute_options) {
            const optionValues = attribute_options[value];

            if (optionValues) {
                if (!isProductCountVisible) {
                    const { label } = optionValues;

                    return { ...optionValues, labelText: label };
                }

                const { label, count = 0 } = optionValues;

                const adjustedLabel = getBooleanLabel(label, is_boolean);

                return {
                    ...optionValues,
                    label: adjustedLabel,
                    labelText: adjustedLabel,
                    count
                };
            }
        }

        return {};
    }

    renderImageValue(img, label) {
        const {
            isFormattedAsText
        } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        return (
            <>
                <img
                  block="ProductAttributeValue"
                  elem="Image"
                  src={ `/media/attribute/swatch${img}` }
                  alt={ label }
                />
                <data
                  block="ProductAttributeValue"
                  elem="Image-Overlay"
                  value={ label }
                  title={ label }
                />
                <span
                  block="ProductAttributeValue"
                  elem="Label"
                >
                    { label }
                </span>
            </>
        );
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value, attribute_options },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
            isSelected
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let type;
        for (const key in attribute_options) {
            type = attribute_options[key]?.swatch_data?.type;
        }

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex="-1"
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                { this.renderAttributeByType() }
                </span>
            );
        }

        return (
            <a
              href={ href }
              block="ProductAttributeValue"
              mods={ { isNotAvailable, type, isSelected } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default ProductAttributeValueComponent;
