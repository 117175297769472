/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import AddToCart from 'Component/AddToCart';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import CartIcon from 'Component/Icons/CartIcon';
import Loader from 'Component/Loader';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './ProductCard.override.style';

/** @namespace MasafiFrontend/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    renderQuantityChangeField() {
        const {
            quantity: qty,
            product: {
                sku,
                stock_item: {
                    in_stock: isProductInStock,
                    qty_increments: qtyIncrement = 1,
                    min_sale_qty: minSaleQuantity,
                    max_sale_qty: maxSaleQuantity

                } = {}
            },
            handleChangeQuantity
        } = this.props;

        if (!isProductInStock) {
            return <div block="ProductCard" elem="QuantityWrapper" mods={ { isPlaceholder: true } } />;
        }

        return (
            <div
              block="ProductCard"
              mix={ { block: 'CartItem' } }
              elem="QuantityWrapper"
              onClick={ this.quantityClickHandler }
              role="button"
              tabIndex="-1"
            >
                <Field
                  id="item_qty"
                  type={ FIELD_TYPE.number }
                  attr={ {
                      id: `${sku}_item_qty`,
                      name: `${sku}_item_qty`,
                      value: qty,
                      defaultValue: qty,
                      min: minSaleQuantity,
                      max: maxSaleQuantity,
                      step: qtyIncrement
                  } }
                  events={ {
                      onChange: handleChangeQuantity
                  } }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.numeric,
                      range: {
                          min: minSaleQuantity,
                          max: maxSaleQuantity
                      }
                  } }
                  validateOn={ ['onChange'] }
                  mix={ { block: 'CartItem', elem: 'Qty' } }
                />
            </div>
        );
    }

    renderAddToCartButton() {
        const {
            inStock,
            quantity,
            getActiveProduct
        } = this.props;

        return (
            <AddToCart
              mix={ { block: this.className, elem: 'AddToCart' } }
              isDisabled={ !inStock }
              isIconEnabled={ false }
              layout={ GRID_LAYOUT }
              quantity={ quantity }
              product={ getActiveProduct() }
            />
        );
    }

    renderPromotionStrap() {
        const {
            product: {
                attributes: { promotion_field: { attribute_value: promotion_field } = {} } = {}
            }
        } = this.props;

        if (!promotion_field) {
            return null;
        }

        return (
            <div>
            <div className="pointer">
                <h4 elem="Brand" itemProp="brand">
                    <TextPlaceholder content={ promotion_field } />
                </h4>
            </div>
            </div>
        );
    }

    renderCardContent() {
        const { renderContent, productName, product: { url } } = this.props;
        //  console.log(this.props);
        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (

            <>
             { this.renderPromotionStrap() }
                { this.renderCardLinkWrapper((
                   <div block="ProductCard" elem="ImageWrapper">
                        { this.renderPicture() }
                   </div>
                )) }
                    <div block="ProductCard" elem="Content">
                        { this.renderBrand() }
                        <a href={ url }>
                            <p className="ProductCard-Name">
                                <TextPlaceholder content={ productName } length="medium" />
                            </p>
                        </a>
                        { this.renderPrice() }
                        <div block="ProductCard" elem="ActionsWrapper">
                            { this.renderQuantityChangeField() }
                            { this.renderAddToCart() }
                        </div>
                    </div>
            </>
        );
    }

    renderAddToCart() {
        const {
            layout,
            showSelectOptionsNotification,
            inStock
        } = this.props;

        if (inStock && this.requiresConfiguration()) {
            return (
                <button
                  block="Button hhel Button_layout_grid Button_isHollow ProductCard-AddToCart"
                  mods={ { layout, isHollow: false } }
                  onClick={ showSelectOptionsNotification }
                >
                { __('Add to cart') }
                    <CartIcon />
                </button>
            );
        }

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <p>
                        { __('Out of stock') }
                    </p>
                </div>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    render() {
        const {
            children,
            mix,
            isLoading
        } = this.props;

        return (
            <li
              block="ProductCard"
              mods={ { GRID_LAYOUT } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
