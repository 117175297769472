import { EVENT_EXECUTED, EXECUTE_EVENT, UPDATE_GMT_CONFIG } from './GoogleTagManager.action';

/** @namespace GtmNew/Store/GoogleTagManager/Reducer/getInitialState */
export const getInitialState = () => ({
    isExecuted: true,
    config: {
        enabled: false,
        events: {}
    }
});

/** @namespace GtmNew/Store/GoogleTagManager/Reducer/GoogleTagManagerReducer */
export const GoogleTagManagerReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_GMT_CONFIG:
        const { payload } = action;

        return {
            ...state,
            config: payload.gtm
        };

    case EVENT_EXECUTED:
        return {
            ...state,
            isExecuted: true
        };

    case EXECUTE_EVENT:
        return {
            ...state,
            event: action.event,
            custom: action.custom,
            isExecuted: false
        };

    default:
        return state;
    }
};

export default GoogleTagManagerReducer;
